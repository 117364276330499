<template>
  <el-row :gutter="15">
<!--    <el-col :span="12" class="mb-15">-->
<!--      <formList-->
<!--          formSize="small"-->
<!--          :formLabel="searchFormLabel"-->
<!--          :form="searchParams"-->
<!--          formLabelWidth="0"-->
<!--      ></formList>-->
<!--    </el-col>-->
<!--    <el-col :span="2">-->
<!--      <el-button size="small" type="primary" @click="searchList" :loading="loading.listLoading">搜索</el-button>-->
<!--    </el-col>-->
<!--    <el-col :span="5" class="ta-r">-->
<!--      <span v-if="invoiceList.length !== 0">-->
<!--        <el-checkbox v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>-->
<!--      </span>-->
<!--      <el-button size="small" type="success"-->
<!--                 :disabled="invoiceList.length === 0 || searchParams.status === '4'"-->
<!--                 @click="downloadFile">下载</el-button>-->
<!--      <el-button size="small" type="warning"-->
<!--                 :disabled="invoiceList.length === 0 || searchParams.status === '1'"-->
<!--                 @click="reOpen">重开</el-button>-->
<!--    </el-col>-->
    <el-col :span="24" v-loading="loading.listLoading"></el-col>
    <el-col :span="24" v-if="invoiceList.length === 0 && !loading.listLoading">
      <el-empty class="py-80" description="暂无数据">
        <el-button v-if="!isLogin" size="small" type="primary" @click="cacheFromInfo">去登录</el-button>
      </el-empty>
    </el-col>
    <el-col
        v-else
        :span="8"
        class="mb-15"
        v-for="inv in invoiceList">
      <el-card class="box-card" :class="{bgChecked: inv.flag}">
        <div slot="header" class="clearfix" style="line-height: 28px">
          <el-checkbox
              v-model="inv.flag"
              :label="inv.mvlicense"
              @change="checkInv(inv)"></el-checkbox>
          <!-- 1 正常(开票成功)；2 红冲；3 作废；4 开票失败 -->
          <el-tag v-if="inv.status==='1'" class="fr" size="medium" type="success">开票成功</el-tag>
<!--          <el-tag v-if="inv.status==='2'||inv.status==='3'" class="fr" size="medium" type="warning">作废</el-tag>-->
          <el-tag v-if="inv.status==='4'" class="fr" size="medium" type="info">开票失败</el-tag>
        </div>
        <ul>
          <li v-if="inv.invoiceNo">
            <i class="tc-link mr-5 el-icon-price-tag"></i>
            发票号码：{{ inv.invoiceNo }}
          </li>
          <li v-else>
            <i class="tc-link mr-5 el-icon-price-tag"></i>
            发票税号：{{ inv.taxid }}
          </li>
          <li class="cp">
            <i class="tc-link mr-5 el-icon-tickets"></i>
            发票抬头：
            <el-tooltip class="item" effect="dark" :content="inv.header" placement="top">
              <span>{{ inv.header }}</span>
            </el-tooltip>
          </li>
          <li v-if="inv.status==='4'">
            <i class="tc-link mr-5 el-icon-warning-outline"></i>
            失败原因：
            <el-tooltip class="item tc-error" effect="dark" :content="showErrMsg(inv.msg)" placement="top">
              <span>{{ showErrMsg(inv.msg) }}</span>
            </el-tooltip>
          </li>
          <li v-else>
            <i class="tc-link mr-5 el-icon-time"></i>
            开票时间：{{ inv.invoiceDate }}
          </li>
          <li>
            <i class="tc-link mr-5 el-icon-map-location"></i>
            汽渡名称：{{ inv.spare2 }}
          </li>
          <li class="tc-link fs-24 posAB">￥{{ inv.money }}</li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import interfaceWechat from "@/mixins/interfaceWechat";
import publicFun from "@/mixins/public";
import formList from "@/components/formList";

import axios from "axios";
import JSZip from'jszip'
import FileSaver from'file-saver'

const getFile = url => {
  console.log('00000', url)
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "blob"
    })
        .then(data => {
          console.log('1111111', data)
          resolve(data.data);
        })
        .catch(error => {
          console.log('2222', error)
          reject(error.toString());
        });
  });
};

export default {
  name: "invoiceList",
  mixins: [interfaceWechat, publicFun],
  components: {
    formList
  },
  data() {
    return {
      isLogin: sessionStorage.userinfo,
      searchFormLabel: [
        {
          colSpan: 10,
          label: '',
          type: 'text',
          key: 'mvlicense',
          placeholder: '车牌号'
        },
        {
          colSpan: 14,
          label: '',
          type: 'datetimerangePicker',
          typeName: 'datetimerange',
          key: 'datetime',
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          width: '100%'
        },
      ],
      searchParams: {
        unionid: '',
        status: '',
        mvlicense: '',
        datetime: [],
        datetimebegin: '',
        datetimeend: ''
      },
      loading: {
        listLoading: false
      },
      invoiceList: [],
      checkedList: [],
      checkedIdList: [],
      checkedStatusList: [],
      checkedUrlList: [],
      checkedCodeList: [],
      checkAll: false
    }
  },
  watch: {
    '$route.params': function (val) {
      if (this.searchParams.unionid) this.getList();
    },
  },
  mounted() {
    this.searchParams.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
    if (this.searchParams.unionid) this.getList();
  },
  methods: {
    searchList(params) {
      this.searchParams.datetimebegin = params.datetime ? params.datetime[0] : '';
      this.searchParams.datetimeend = params.datetime ? params.datetime[1] : '';
      this.searchParams.mvlicense = params.mvLicense;
      this.checkAll = false;
      this.clearCheck();
      this.invoiceList = [];
      this.getList();
    },
    getList() {
      this.searchParams.status = this.$route.params.status;
      this.getInfo('/queryticket/list', this.searchParams, 'invoiceList');
    },
    clearCheck() {
      this.checkedList = [];
      this.checkedIdList = [];
      this.checkedStatusList = [];
      this.checkedUrlList = [];
      this.checkedCodeList = [];
    },
    showErrMsg(msg) {
      let showMsg = '';
      if (msg.indexOf('|') > -1) {
        let arr = msg.split('|');
        showMsg = [];
        arr.forEach(a => {
          showMsg.push(a.split(':')[1] + '');
        });
        showMsg = showMsg.join('；');
      } else {
        showMsg = msg.split(':')[1];
      }
      return showMsg;
    },
    handleCheckAll() {
      this.clearCheck();
      this.invoiceList.forEach(item => {
        item.flag = this.checkAll;
        if (item.flag) {
          this.checkedList.push(item);
          this.checkedIdList.push(item.id);
          this.checkedStatusList.push(item.status);
          this.checkedUrlList.push(item.downloadUrl);
          this.checkedCodeList.push(item.code);
        } else {
          this.clearCheck();
        }
      });
      this.invoiceList.splice(); // 更新视图：解决多选框数据改变，视图不变问题
    },
    checkInv(item) {
      if (item.flag) {
        this.checkedList.push(item);
        this.checkedIdList.push(item.id);
        this.checkedStatusList.push(item.status);
        this.checkedUrlList.push(item.downloadUrl);
        this.checkedCodeList.push(item.code);
      } else {
        let index = this.checkedIdList.findIndex(id => {return id === item.id})
        this.checkedList.splice(index, 1);
        this.checkedIdList.splice(index, 1);
        this.checkedStatusList.splice(index, 1);
        this.checkedUrlList.splice(index, 1);
        this.checkedCodeList.splice(index, 1);
      }
      this.invoiceList.splice(); // 更新视图：解决多选框数据改变，视图不变问题
      this.checkAll = this.checkedList.length === this.invoiceList.length;
      this.$parent.checkAll = this.checkAll;
      this.getTotal();
    },
    getTotal() {
      if (this.checkedList.length===0) {
        this.$parent.totalMoney = 0;
        return;
      }
      let total = 0;
      this.checkedList.forEach(i => {
        total += Number(i.money);
      });
      this.$parent.totalMoney = total;
    },
    downloadFile() {
      if (this.checkedList.length === 0) return this.$message.info('请勾选需要下载的数据。');
      // if (this.checkedStatusList.indexOf('2')>-1||this.checkedStatusList.indexOf('3')>-1) return this.$message.info('请去掉作废的数据。');
      // if (this.checkedStatusList.indexOf('4')>-1) return this.$message.info('请去掉开票失败的数据。');
      this.checkedUrlList.forEach((item, index) => {
        let _this = this;
        setTimeout(() => {
          this.exportFile(item);
        }, index * 1000)
      });
    },
    exportFile(data) {
      if(!data)return;
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    reOpen() {
      if (this.checkedList.length === 0) return this.$message.info('请勾选需要重开的数据。');
      // if (this.checkedStatusList.indexOf('1')>-1) return this.$message.info('请去掉开票成功的数据。');
      this.$router.push({
        name: 'invoice',
        params: {
          type: 'invoiceReopen',
          item: 'carInfo'
        }
      });
      sessionStorage.carInfo = JSON.stringify(this.checkedList);
    },
    getFile(url) {
      console.log('00000', url)
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url,
          responseType: "blob"
        })
            .then(data => {
              console.log('1111111', data)
              resolve(data.data);
            })
            .catch(error => {
              console.log('2222', error)
              reject(error.toString());
            });
      });
    },
    downLoadAll() {
      const data = this.checkedUrlList; // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach(item => {
        const promise = this.getFile(item).then(data => {
          console.log('&&&&&&', item)
          // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split("/");
          const file_name = arr_name[arr_name.length - 1]; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      let _this = this;
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, "发票.zip"); // 利用file-saver保存文件  自定义文件名
          _this.$parent.loading.downLoading = false;
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  position: relative;
  padding: 0;
  li {
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.posAB {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.el-card {
  ::v-deep {
    .el-card__header {
      padding: 12px 20px;
    }
  }
}
.el-checkbox {
  margin-right: 10px !important;
  ::v-deep {
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      &::after {
        left: 5px;
        width: 4px;
        height: 10px;
      }
    }
  }
}
.bgChecked {
  background: #f6f7ff;
}
</style>